"use strict";

Object.defineProperty(exports, "__esModule", { value: true });
exports.DevLiveShareHost = void 0;
var live_share_1 = require("@microsoft/live-share");
/**
 * Live Share Host implementation used for local testing.
 */
var DevLiveShareHost = /** @class */ (function () {
    /**
     * @hidden
     */
    function DevLiveShareHost(endpoint, _getLocalTestContainerId, _setLocalTestContainerId) {
        this._getLocalTestContainerId = _getLocalTestContainerId;
        this._setLocalTestContainerId = _setLocalTestContainerId;
        this.endpoint = "http://localhost:7070";
        this.clientsMeetingRoles = [
            live_share_1.UserMeetingRole.organizer,
            live_share_1.UserMeetingRole.presenter,
            live_share_1.UserMeetingRole.attendee,
        ];
        this.endpoint = endpoint;
    }
    /**
     * Creates an new live share host for local testing.
     * @param getLocalTestContainerId Optional. Function to customize reading the test container ID. The default implementation reads the ID from the browsers # URL.
     * @param setLocalTestContainerId Optional. Function to customize saving the test container ID. The default implementation appends the ID to the browsers # URL.
     * @returns
     */
    DevLiveShareHost.create = function (endpoint, getLocalTestContainerId, setLocalTestContainerId) {
        return new DevLiveShareHost(endpoint, getLocalTestContainerId, setLocalTestContainerId);
    };
    DevLiveShareHost.prototype.getFluidTenantInfo = function () {
        return Promise.resolve({
            tenantId: "local",
            ordererEndpoint: this.endpoint,
            storageEndpoint: this.endpoint,
            serviceEndpoint: this.endpoint,
        });
    };
    DevLiveShareHost.prototype.getFluidToken = function (containerId) {
        return Promise.resolve(DevLiveShareHost.LOCAL_MODE_TEST_TOKEN);
    };
    DevLiveShareHost.prototype.getFluidContainerId = function () {
        var containerId = this.getLocalTestContainerId();
        return Promise.resolve({
            containerState: containerId
                ? live_share_1.ContainerState.alreadyExists
                : live_share_1.ContainerState.notFound,
            shouldCreate: !containerId,
            containerId: containerId,
            retryAfter: 0,
        });
    };
    DevLiveShareHost.prototype.setFluidContainerId = function (containerId) {
        this.setLocalTestContainerId(containerId);
        return Promise.resolve({
            containerState: live_share_1.ContainerState.added,
            containerId: containerId,
            shouldCreate: false,
            retryAfter: 0,
        });
    };
    DevLiveShareHost.prototype.getNtpTime = function () {
        var now = new Date();
        return Promise.resolve({
            ntpTime: now.toUTCString(),
            ntpTimeInUTC: now.getTime(),
        });
    };
    DevLiveShareHost.prototype.registerClientId = function (clientId) {
        return Promise.resolve(this.clientsMeetingRoles);
    };
    DevLiveShareHost.prototype.getClientRoles = function (clientId) {
        return Promise.resolve(this.clientsMeetingRoles);
    };
    DevLiveShareHost.prototype.getLocalTestContainerId = function () {
        if (this._getLocalTestContainerId) {
            return this._getLocalTestContainerId();
        }
        else if (window.location.hash) {
            return window.location.hash.substring(1);
        }
        else {
            return undefined;
        }
    };
    DevLiveShareHost.prototype.setLocalTestContainerId = function (containerId) {
        if (this._setLocalTestContainerId) {
            this._setLocalTestContainerId(containerId);
        }
        else {
            window.location.hash = containerId;
        }
    };
    DevLiveShareHost.LOCAL_MODE_TEST_TOKEN = "test-token";
    return DevLiveShareHost;
}());
exports.DevLiveShareHost = DevLiveShareHost;
